// import Homepage from "./pages/homepage";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import AboutUS from "./pages/about";
// import PartnerRegistration from "./pages/partner_registration";
// import ProductPage from "./pages/product";
// import CartPage from "./pages/cart_page";
// import HowtoOrder from "./pages/howto_order_page";
// import ArticlePages from "./pages/articlepages";
import RegistrationSimplified from "./pages/partner_registration_simplified";
// import FeedbackPagesPrivate from "./pages/feedback_pages_private";
import Questioner from "./pages/questioner";
import NotFoundPage from "./pages/not-found-pages";

const Routing = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={RegistrationSimplified} />
        {/* <Route exact path="/" component={Homepage} />
        <Route path="/about" component={AboutUS} />
        <Route path="/partner-registration" component={PartnerRegistration} />
        <Route
          path="/registration-simplified"
          component={RegistrationSimplified}
        />
        <Route path="/how-to-order" component={HowtoOrder} />
        <Route path="/products" component={ProductPage} />
        <Route path="/cart" component={CartPage} />
        <Route path="/blog" component={ArticlePages} />

        <Route path="/feedback-form" component={FeedbackPagesPrivate} /> */}

        <Route path="/questioner-satisfaction" component={Questioner} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routing;
