import { createTheme, colors } from '@material-ui/core';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#285A5B',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#FFFFFF',
      main: '#285A5B',
      soft: '#4F7170'
    },
    secondary: {
      main: '#FEFDFC',
      accent: '#F4B74B',
      highlight: '#E9404C'
    },
    text: {
      primary: '#285A5B',
      secondary: '#FFB84D',
      title: '#371708',
      subtitle: '#639c84',
      accent: '#9c637c'
    }
  },
  typography
});

export default theme;