import { Box, Button, CssBaseline } from "@material-ui/core";
import NotFoundImg from "../assets/not-found.jpg";

const NotFoundPage = () => {
  return (
    <>
      <CssBaseline />
      <Box
        width="100%"
        height="100vh"
        backgroundColor="white"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <img src={NotFoundImg} alt="not-found" width="700px" />
        <Button href="/">Back to Home</Button>
      </Box>
    </>
  );
};

export default NotFoundPage;
