import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  InputAdornment,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { useFormik } from "formik";
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";

import AccountCircle from "@material-ui/icons/AccountCircle";
// import MailIcon from "@material-ui/icons/Mail";
import BrandingWatermarkIcon from "@material-ui/icons/BrandingWatermark";
// import LocationOnIcon from "@material-ui/icons/LocationOn";
// import AccessibilityIcon from "@material-ui/icons/Accessibility";

import * as Yup from "yup";
import Swal from "sweetalert2";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const productNeedList = [
  "ayam utuh/potong",
  "filet ",
  "sayap",
  "kulit",
  "jeroan/ekses (usus, ati ampela, kepala, ceker, tulang, lemak)",
  "custom (filet slice, filet dadu, filet giling)",
];

const foodType = ["Frozen", "Non-Frozen"];

const avgNeedList = [
  "1-9 kg",
  "10-19 kg",
  "20-50 kg",
  "50-200 kg",
  "200-500 kg",
  "500 kg ke atas",
];

const platformInfo = [
  {
    value: 0,
    type_name: "Google",
  },
  {
    value: 1,
    type_name: "Facebook",
  },
  {
    value: 2,
    type_name: "Instagram",
  },
  {
    value: 3,
    type_name: "Teman/Kerabat",
  },
  {
    value: 4,
    type_name: "Influencer / KOL / Artis",
  },
  {
    value: 5,
    type_name: "Youtube",
  },
  {
    value: 6,
    type_name: "TikTok",
  },
];

const bsCategory = [
  {
    value: 0,
    type_name: "pribadi",
  },
  {
    value: 1,
    type_name: "perusahaan",
  },
  {
    value: 2,
    type_name: "franchise",
  },
  {
    value: 3,
    type_name: "pemerintahan",
  },
];

const RegistrationSimplified = () => {
  let query = useQuery();
  const [listProv, setListProv] = useState([1]);
  const [listCity, setListCity] = useState([1]);
  const [listDistrict, setListDistrict] = useState([1]);
  // const history = useHistory();
  const [isChecked, setChecked] = useState(
    new Array(productNeedList.length).fill(false)
  );
  const [foodTypeChecked, setFoodTypeChecked] = useState(
    new Array(foodType.length).fill(false)
  );

  // Input other product need
  const [inputOtherCheck, setInputOtherCheck] = useState(false);
  const [otherProduct, setOtherProduct] = useState("");

  const brandTypeList = [
    {
      value: 0,
      type_name: "Jajanan/Olahan",
    },
    {
      value: 1,
      type_name: "Cafe/Restoran/Warung Nasi",
    },
    {
      value: 2,
      type_name: "Hotel",
    },
    {
      value: 3,
      type_name: "Catering",
    },
    {
      value: 4,
      type_name: "Supplier/Reseller",
    },
    {
      value: 5,
      type_name: "Supermarket/Kios Pasar",
    },
    {
      value: 6,
      type_name: "Retail/Rumah Tangga",
    },
  ];

  const frekOrder = [
    {
      value: 0,
      frek_order: "1-3 (lebih dari 2 minggu, 1 kali)",
    },
    {
      value: 1,
      frek_order: "4-7 (1 minggu, 1 kali)",
    },
    {
      value: 2,
      frek_order: "8-11 (3 hari, 1 kali)",
    },
    {
      value: 3,
      frek_order: "12-23 (2 hari, 1 kali)",
    },
    {
      value: 4,
      frek_order: ">23 (Setiap hari)",
    },
  ];

  useEffect(() => {
    axios({
      method: "get",
      // url: `${process.env.REACT_APP_BASE_URL}/api/province`,
      url: `${
        process.env.REACT_APP_STATUS === "deployment"
          ? process.env.REACT_APP_BASE_URL
          : process.env.REACT_APP_BASE_URL_LOCAL
      }/api/province`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        setListProv(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Update value of checkbox group
  useEffect(() => {
    let newProdNeedList = [];
    isChecked.forEach((item, index) => {
      if (item) {
        newProdNeedList.push(productNeedList[index]);
      }
    });

    // Adding product need to list
    if (inputOtherCheck && otherProduct !== "") {
      newProdNeedList.push(otherProduct);
    }

    let txtNewProdList = newProdNeedList.join(",");
    formik.setFieldValue("product_need", txtNewProdList);
  }, [isChecked, inputOtherCheck, otherProduct]);

  // Update value for foodType checkbox group
  useEffect(() => {
    let choosenFoodType = [];

    //Adding choosen food type to list
    foodTypeChecked.forEach((item, index) => {
      if (item) {
        choosenFoodType.push(foodType[index]);
      }
    });

    let txtChoosenFoodType = choosenFoodType.join(",");

    // console.log(txtChoosenFoodType);
    formik.setFieldValue("food_type", txtChoosenFoodType);
  }, [foodTypeChecked]);

  // Handling event on prov change
  const onProvChange = (event) => {
    formik.setFieldValue("locaiction_state", `${event.target.value}`);

    console.log(process.env.REACT_APP_STATUS);

    axios({
      method: "get",
      // url: `${process.env.REACT_APP_BASE_URL}/api/city/${event.target.value}`,
      url: `${
        process.env.REACT_APP_STATUS === "deployment"
          ? process.env.REACT_APP_BASE_URL
          : process.env.REACT_APP_BASE_URL_LOCAL
      }/api/city/${event.target.value}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        setListCity(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });

    return event.target.value;
  };

  // Handling event on city change
  const onCityChange = (event) => {
    axios({
      method: "get",
      // url: `${process.env.REACT_APP_BASE_URL}/api/city/${event.target.value}`,
      url: `${
        process.env.REACT_APP_STATUS === "deployment"
          ? process.env.REACT_APP_BASE_URL
          : process.env.REACT_APP_BASE_URL_LOCAL
      }/api/district/${event.target.value}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        setListDistrict(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });

    let cityID = event.target.value;
    let indexCity = _.findKey(listCity, (city) => {
      return city.id === cityID;
    });
    let choosenCity = listCity[indexCity].nama;
    return choosenCity;
  };

  const normPhoneNumber = (event) => {
    let rawNumber = event.target.value;
    let numArr = Array.from(rawNumber);
    // console.log(rawNumber);

    if (numArr[0] === "0" && numArr.length > 1) {
      numArr.shift();
    }

    rawNumber = numArr.join("");
    return rawNumber;
  };

  // Handling fproduct need change
  const prodNeedChange = (pos) => {
    let updateCheckedState = isChecked.map((item, index) => {
      return index === pos ? !item : item;
    });

    setChecked(updateCheckedState);
  };

  // Handling foodType change
  const foodTypeChange = (pos) => {
    let updateCheckedState = foodTypeChecked.map((item, index) => {
      return index === pos ? !item : item;
    });

    setFoodTypeChecked(updateCheckedState);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_name: query.get("name"),
      // user_age: "",
      user_phone: query.get("phone"),
      // user_email: query.get("email"),
      location_state: "",
      location_city: "",
      // address: "",
      // postal_code: "",
      brand_name: "",
      brand_type: query.get("type"),
      avg_need_kg: "",
      product_need: "",
      food_type: "",
      location_district: "",
      platform_info: "",
      frek_order: "",
      bs_category: "",
    },
    validationSchema: Yup.object({
      // user_email: Yup.string().email("Alamat email invalid"),
      // user_age: Yup.number(),
      product_need: Yup.string().required("Mohon pilih salah satu"),
      food_type: Yup.string().required("Mohon pilh salah satu"),
    }),
    onSubmit: (values) => {
      console.log(values);

      let waPayload = `Saya sudah bergabung dengan data sebagai berikut : \n\
      Nama  : ${values.user_name}\n\
      Kontak : +62${values.user_phone}\n\
      Nama usaha : ${values.brand_name}\n\
      Kebutuhan rata-rata : ${avgNeedList[values.avg_need_kg]}\n\
      Kebutuhan produk : ${values.product_need}\n\
      Tipe produk : ${values.food_type}\n\
      Kota : ${values.location_city}\n\
      Kecamatan : ${values.location_district}\n\
      Order per bulan : ${values.frek_order} `;

      let waPayload_encoded = encodeURIComponent(waPayload);

      let locLower = values.location_city.toLocaleLowerCase();
      let districtLower = values.location_district.toLocaleLowerCase();
      let needKg = values.avg_need_kg;

      let waLink = ``;

      if (
        (needKg === 0 || needKg === 1 || needKg === 2) &&
        (locLower.includes("kota bandung") ||
          locLower.includes("cimahi") ||
          districtLower.includes("cileunyi") ||
          districtLower.includes("padalarang"))
      ) {
        waLink = `https://wa.me/6281321096910?text=${waPayload_encoded}`;
      } else {
        waLink = `https://wa.me/6281388714281?text=${waPayload_encoded}`;
      }
      // console.log(waLink);

      axios({
        method: "post",
        // url: `${process.env.REACT_APP_BASE_URL}/api/partner`,
        url: `${
          process.env.REACT_APP_STATUS === "deployment"
            ? process.env.REACT_APP_BASE_URL
            : process.env.REACT_APP_BASE_URL_LOCAL
        }/api/partner`,
        data: values,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((data) => {
          // console.log(data)
          if (data.data.success) {
            Swal.fire({
              icon: "success",
              // title: "Pendaftaran Berhasil",
              title: data.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
            window.location.replace(waLink);
          } else {
            Swal.fire({
              icon: "error",
              title: data.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Terjadi masalah saat pendaftaran, Mohon Maaf.",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
  });

  return (
    <>
      <Helmet>
        <title>Pendaftaran Mitra | RPA SUKAHATI</title>
      </Helmet>

      <Box minHeight="100vh" backgroundColor="primary.main">
        <Box
          sx={{
            backgroundColor: "secondary.accent",
            borderRadius: 2,
            padding: 2,
            flexDirection: "column",
          }}
        >
          {/* Title */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              justifyContent: "space-between",
              paddingBottom: 2,
            }}
          >
            <Typography
              fontFamily="Oswald"
              fontSize="35px"
              fontWeight={1000}
              color="primary.main"
              align="left"
              lineHeight="1.2"
            >
              Form Pendaftaran Mitra
            </Typography>
          </Box>

          <Box
            sx={{
              flexDirection: "column",
              backgroundColor: "ivory",
              borderRadius: 4,
              p: 2,
            }}
          >
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6} sm={12}>
                  <Box
                    sx={{
                      py: 2,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      required
                      id="user_name"
                      label="Nama Lengkap"
                      variant="filled"
                      size="small"
                      color="primary"
                      {...formik.getFieldProps("user_name")}
                      InputLabelProps={{ style: { color: "#4F7170" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />

                    {/* <br />
                    <TextField
                      required
                      variant="filled"
                      id="user_age"
                      label="Umur"
                      size="small"
                      color="primary"
                      autoComplete="off"
                      {...formik.getFieldProps("user_age")}
                      InputLabelProps={{ style: { color: "#4F7170" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccessibilityIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {formik.touched.user_age && formik.errors.user_age ? (
                      <Typography
                        fontFamily="Roboto"
                        fontSize="1em"
                        color="secondary.highlight"
                        sx={{ py: 0.5 }}
                      >
                        Invalid age
                      </Typography>
                    ) : null} */}

                    <br />
                    <TextField
                      required
                      variant="filled"
                      id="user_phone"
                      label="Nomor Handphone"
                      size="small"
                      color="primary"
                      value={formik.values.user_phone}
                      onChange={(e) => {
                        formik.setFieldValue("user_phone", normPhoneNumber(e));
                      }}
                      InputLabelProps={{ style: { color: "#4F7170" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <font color="#4F7170">+62</font>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {/* <br />
                    <TextField
                      variant="filled"
                      id="user_email"
                      label="Email"
                      size="small"
                      color="primary"
                      {...formik.getFieldProps("user_email")}
                      InputLabelProps={{ style: { color: "#4F7170" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {formik.touched.user_email && formik.errors.user_email ? (
                      <Typography
                        fontFamily="Roboto"
                        fontSize="1em"
                        color="secondary.highlight"
                        sx={{ py: 0.5 }}
                      >
                        {formik.errors.user_email}
                      </Typography>
                    ) : null} */}

                    <br />
                    <TextField
                      required
                      id="brand_name"
                      label="Nama Brand/Usaha"
                      variant="filled"
                      size="small"
                      color="primary"
                      value={formik.values.brand_name}
                      onChange={formik.handleChange}
                      InputLabelProps={{ style: { color: "#4F7170" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BrandingWatermarkIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <br />
                    <Typography
                      fontFamily="Roboto"
                      fontWeight={700}
                      fontSize="12px"
                      align="left"
                      color="primary.main"
                    >
                      Jenis usaha
                    </Typography>
                    <Select
                      required
                      variant="filled"
                      id="brand_type"
                      size="small"
                      color="primary"
                      defaultValue=""
                      {...formik.getFieldProps("brand_type")}
                    >
                      {brandTypeList.map((item, index) => (
                        <MenuItem key={index} value={item.type_name}>
                          {item.type_name}
                        </MenuItem>
                      ))}
                    </Select>
                    <br />

                    <Typography
                      fontFamily="Roboto"
                      fontWeight={700}
                      fontSize="12px"
                      align="left"
                      color="primary.main"
                    >
                      Kepemilikan usaha
                    </Typography>
                    <Select
                      required
                      variant="filled"
                      id="brand_type"
                      size="small"
                      color="primary"
                      defaultValue=""
                      {...formik.getFieldProps("bs_category")}
                    >
                      {bsCategory.map((item, index) => (
                        <MenuItem key={index} value={item.type_name}>
                          {item.type_name}
                        </MenuItem>
                      ))}
                    </Select>
                    <br />

                    <Typography
                      fontFamily="Roboto"
                      fontWeight={700}
                      fontSize="12px"
                      align="left"
                      color="primary.main"
                    >
                      Kebutuhan rata-rata per order (Kg)
                    </Typography>
                    <Select
                      required
                      variant="filled"
                      id="avg_need_kg"
                      size="small"
                      color="primary"
                      {...formik.getFieldProps("avg_need_kg")}
                    >
                      <MenuItem value={0}> 1-9 kg </MenuItem>
                      <MenuItem value={1}> 10-19 kg </MenuItem>
                      <MenuItem value={2}> 20-50 kg </MenuItem>
                      <MenuItem value={3}> 50-200 kg</MenuItem>
                      <MenuItem value={4}> 200-500 kg</MenuItem>
                      <MenuItem value={5}> 500 kg ke atas</MenuItem>
                    </Select>

                    <br />
                    <Typography
                      fontFamily="Roboto"
                      fontWeight={700}
                      fontSize="12px"
                      align="left"
                      color="primary.main"
                    >
                      Kebutuhan produk
                    </Typography>

                    <Box
                      sx={{
                        // width: "100%",
                        maxWidth: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                      }}
                    >
                      {formik.touched.product_need &&
                      formik.errors.product_need ? (
                        <Typography
                          fontFamily="Roboto"
                          fontSize="1em"
                          color="secondary.highlight"
                          sx={{ py: 0.8 }}
                        >
                          {formik.errors.product_need}
                        </Typography>
                      ) : null}
                      {productNeedList.map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "100%",
                            height: "2em",
                            paddingTop: "1.2em",
                            paddingBottom: "1em",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            id="product_need"
                            checked={isChecked[index]}
                            onChange={() => {
                              prodNeedChange(index);
                            }}
                          />
                          <Typography
                            fontFamily="Roboto"
                            fontWeight={500}
                            fontSize="12px"
                            align="left"
                            color="primary.main"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {item}
                          </Typography>
                        </Box>
                      ))}
                      <Box
                        sx={{
                          height: "2em",
                          width: "100%",
                          paddingTop: "1.2em",
                          paddingBottom: "1em",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Checkbox
                          id="input_other"
                          checked={inputOtherCheck}
                          onChange={() => {
                            setInputOtherCheck((prev_state) => !prev_state);
                            setOtherProduct("");
                          }}
                        />
                        <Typography
                          flex={1}
                          fontFamily="Roboto"
                          fontWeight={500}
                          fontSize="12px"
                          align="left"
                          color="primary.main"
                          sx={{
                            textTransform: "capitalize",
                            marginRight: "1em",
                          }}
                        >
                          Lainnya
                        </Typography>
                        <TextField
                          flex={2}
                          disabled={!inputOtherCheck}
                          variant="standard"
                          style={{
                            width: "100%",
                          }}
                          inputProps={{
                            style: {
                              fontSize: 12,
                              paddingTop: 4,
                              paddingBottom: 4,
                            },
                          }}
                          value={otherProduct}
                          onChange={(event) => {
                            setOtherProduct(event.target.value);
                          }}
                        />
                      </Box>
                    </Box>

                    <br />
                    {/* Frozen or not Frozen */}
                    <Typography
                      fontFamily="Roboto"
                      fontWeight={700}
                      fontSize="12px"
                      align="left"
                      color="primary.main"
                    >
                      Frozen/Non-Frozen
                    </Typography>
                    <Box
                      sx={{
                        // width: "100%",
                        maxWidth: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                      }}
                    >
                      {formik.touched.food_type && formik.errors.food_type ? (
                        <Typography
                          fontFamily="Roboto"
                          fontSize="1em"
                          color="secondary.highlight"
                          sx={{ py: 0.8 }}
                        >
                          {formik.errors.food_type}
                        </Typography>
                      ) : null}

                      {foodType.map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "100%",
                            height: "2em",
                            paddingTop: "1.2em",
                            paddingBottom: "1em",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            id="food_type"
                            checked={foodTypeChecked[index]}
                            onChange={() => {
                              foodTypeChange(index);
                            }}
                          />
                          <Typography
                            fontFamily="Roboto"
                            fontWeight={500}
                            fontSize="12px"
                            align="left"
                            color="primary.main"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {item}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Grid>

                <Grid item md={6} sm={12}>
                  <Box
                    sx={{
                      py: 2,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <TextField
                      required
                      multiline
                      id="address"
                      label="Alamat Pengiriman"
                      variant="filled"
                      size="small"
                      color="primary"
                      minRows={3}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      InputLabelProps={{ style: { color: "#4F7170" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnIcon />
                          </InputAdornment>
                        ),
                      }}
                    /> */}

                    {/* Frekuensi order */}
                    <Typography
                      fontFamily="Roboto"
                      fontWeight={700}
                      fontSize="12px"
                      align="left"
                      color="primary.main"
                    >
                      Frekuensi pembelian per bulan
                    </Typography>
                    <Select
                      variant="filled"
                      id="frek_order"
                      size="small"
                      color="primary"
                      defaultValue="1-4"
                      {...formik.getFieldProps("frek_order")}
                    >
                      {frekOrder.map((item, index) => (
                        <MenuItem key={index} value={item.frek_order}>
                          {item.frek_order}
                        </MenuItem>
                      ))}
                    </Select>

                    <br />

                    <Typography
                      fontFamily="Roboto"
                      fontWeight={700}
                      fontSize="12px"
                      align="left"
                      color="primary.main"
                    >
                      Provinsi
                    </Typography>
                    <Select
                      required
                      variant="filled"
                      id="location_state"
                      size="small"
                      color="primary"
                      defaultValue=""
                      onChange={(e) => {
                        formik.setFieldValue("location_state", onProvChange(e));
                      }}
                    >
                      {listProv.map((provinsi, index) => (
                        <MenuItem key={index} value={provinsi.id}>
                          {provinsi.nama}
                        </MenuItem>
                      ))}
                    </Select>

                    <br />
                    <Typography
                      fontFamily="Roboto"
                      fontWeight={700}
                      fontSize="12px"
                      align="left"
                      color="primary.main"
                    >
                      Kota
                    </Typography>
                    <Select
                      required
                      variant="filled"
                      id="location_city"
                      size="small"
                      color="primary"
                      defaultValue=""
                      // {...formik.getFieldProps("location_city")}
                      onChange={(e) => {
                        formik.setFieldValue("location_city", onCityChange(e));
                      }}
                    >
                      {listCity ? (
                        listCity.map((kota, index) => (
                          <MenuItem key={index} value={kota.id}>
                            {kota.nama}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>- Kota -</MenuItem>
                      )}
                    </Select>

                    <br />
                    {/* Kecamatan */}
                    <Typography
                      fontFamily="Roboto"
                      fontWeight={700}
                      fontSize="12px"
                      align="left"
                      color="primary.main"
                    >
                      Kecamatan
                    </Typography>
                    <Select
                      required
                      variant="filled"
                      id="location_city"
                      size="small"
                      color="primary"
                      defaultValue=""
                      {...formik.getFieldProps("location_district")}
                    >
                      {listDistrict ? (
                        listDistrict.map((kecamatan, index) => (
                          <MenuItem key={index} value={kecamatan.name}>
                            {kecamatan.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>- Kecamatan -</MenuItem>
                      )}
                    </Select>
                    {/* <br />
                    <TextField
                      required
                      variant="filled"
                      id="postal_code"
                      label="Kode Pos"
                      size="small"
                      color="primary"
                      value={formik.values.postal_code}
                      onChange={formik.handleChange}
                      InputLabelProps={{ style: { color: "#4F7170" } }}
                    /> */}
                    <br />

                    <Typography
                      fontFamily="Roboto"
                      fontWeight={700}
                      fontSize="12px"
                      align="left"
                      color="primary.main"
                    >
                      Dari mana anda tahu tantang kami ?
                    </Typography>
                    <Select
                      required
                      variant="filled"
                      id="platform_info"
                      size="small"
                      color="primary"
                      defaultValue="Google"
                      {...formik.getFieldProps("platform_info")}
                    >
                      {platformInfo.map((item, index) => (
                        <MenuItem key={index} value={item.type_name}>
                          {item.type_name}
                        </MenuItem>
                      ))}
                    </Select>
                    <br />

                    <Typography
                      fontFamily="Roboto"
                      fontSize="1em"
                      color="text.title"
                      align="left"
                      sx={{ py: 0.5 }}
                    >
                      Sukahati menghargai dan menghormati privasi seluruh mitra.
                      Kami akan selalu menjaga kerahasiaan data-data pribadi
                      mitra.
                    </Typography>

                    <Button type="submit" variant="contained" color="primary">
                      Daftar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RegistrationSimplified;
