import "survey-core/defaultV2.css";
import listQuestions from "../__mocks__/question_model";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { Box, CssBaseline } from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";

const Questioner = () => {
  const history = useHistory();
  const question_model = new Model(listQuestions);

  question_model.onComplete.add((sender, options) => {
    console.log(JSON.stringify(sender.data, null, 3));
    let senderResponse = JSON.stringify(sender.data, null, 3);

    let payloadObj = {
      responses: senderResponse,
      survey_id: "csa-001",
    };

    axios({
      method: "POST",
      url: `${
        process.env.REACT_APP_STATUS === "development"
          ? process.env.REACT_APP_URL_DASHBOARD_LOCAL
          : process.env.REACT_APP_URL_DASHBOARD
      }/dashboard/api/questioner-satisfaction`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: payloadObj,
    })
      .then((data) => {
        if (data.data.success) {
          Swal.fire({
            icon: "success",
            // title: "Pendaftaran Berhasil",
            title: "Response Sent",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push("/");
        } else {
          Swal.fire({
            icon: "error",
            title: "Error Sending Response",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return (
    <>
      <CssBaseline />
      <Box display="flex" width="100%">
        <Survey model={question_model} />
      </Box>
    </>
  );
};

export default Questioner;
