const listQuestions = {
  title: "Survey Kepuasan Pelanggan Sukahati",
  elements: [
    {
      name: "nama_pengisi_kuisioner",
      title: "Nama Pengisi Kuisioner",
      type: "text",
      isRequired: true,
    },
    {
      name: "nama_brand",
      title: "Nama Brand",
      type: "text",
      isRequired: true,
    },
    {
      name: "nama_owner",
      title: "Nama Owner:",
      type: "text",
      isRequired: true,
    },
    {
      type: "checkbox",
      name: "jenis_usaha",
      title: "Jenis Usaha",
      choices: [
        "Jajanan/Olahan",
        "Cafe/Restoran/Warung Nasi",
        "Hotel",
        "Catering",
        "Supplier/Reseller",
        "Supermarket/Kios Pasar",
        "Retail/Rumah Tangga",
      ],
      isRequired: true,
      colCount: 2,
      maxSelectedChoices: 1,
    },
    {
      type: "checkbox",
      name: "segmentasi_market",
      title: "Segmentasi Market",
      description:
        "Pilih segmentasi market yang paling sesuai dengan usaha anda (maksimal 2).",
      choices: ["Mahasiswa", "Warga", "Keluarga", "Wisatawan"],
      isRequired: true,
      colCount: 2,
      showOtherItem: true,
      maxSelectedChoices: 2,
    },
    {
      type: "checkbox",
      name: "mayoritas_penjualan",
      title: "Mayoritas Penjualan",
      choices: ["Online", "Offline/Makan di tempat", "Bawa pulang"],
      isRequired: true,
      colCount: 2,
      maxSelectedChoices: 1,
    },
    {
      type: "checkbox",
      name: "rencana_pengembangan_usaha",
      title: "Rencana Pengembangan Usaha",
      description:
        "Pilih rencana pengembangan yang paling sesuai dengan usaha anda (maksimal 2).",
      choices: [
        "Tambah Cabang Baru",
        "Tambah Menu Baru",
        "Tambah Jenis Usaha baru",
      ],
      isRequired: true,
      colCount: 2,
      showOtherItem: true,
      maxSelectedChoices: 2,
    },
    {
      type: "checkbox",
      name: "frekuensi_transaksi",
      title: "Seberapa Sering Melakukan Transaksi per bulan",
      choices: [
        "1-3 (lebih dari 2 minggu, 1 kali)",
        "4-7 (1 minggu, 1 kali)",
        "8-11 (3 hari, 1 kali)",
        "12-23 (2 hari, 1 kali)",
        ">23 (Setiap hari)",
      ],
      isRequired: true,
      colCount: 2,
      maxSelectedChoices: 1,
    },
    {
      type: "checkbox",
      name: "quantity_setiap_transaksi",
      title: "Kuantitas Setiap Transaksi",
      choices: [
        "1-9 kg",
        "10-19 kg",
        "20-50 kg",
        "50-200 kg",
        "500 kg ke atas",
      ],
      isRequired: true,
      colCount: 2,
      maxSelectedChoices: 1,
    },
    {
      type: "checkbox",
      name: "produk_yg_digunakan",
      title: "Produk Yang Digunakan",
      description: "Pilih 2 yang paling mewakili.",
      choices: [
        "Ayam utuh/potong",
        "Filet",
        "Sayap",
        "Kulit",
        "Jeroan/ekses (usus, ati ampela, kepala, ceker, tulang, lemak)",
        "Custom (filet slice, filet dadu, filet giling)",
      ],
      isRequired: true,
      showOtherItem: true,
      colCount: 2,
    },
    {
      type: "checkbox",
      name: "kondisi_saat_mencari_supplier",
      title: "Kondisi Saat Anda Mencari Supplier",
      description: "Pilih 2 yang paling sesuai dengan keadaan anda.",
      choices: [
        "Ketika harga sedang mahal",
        "Ketika kecewa dengan supplier sebelumnya",
        "Ketika ada menu/produk baru",
        "Memilki jadwal rutin untuk mencari supplier ayam",
      ],
      isRequired: true,
      showOtherItem: true,
      colCount: 1,
      maxSelectedChoices: 2,
    },
    {
      type: "checkbox",
      name: "kendala_saat_mencari_supplier",
      title: "Kendala Saat Anda Mencari Supplier",
      description: "Pilih 2 yang paling sesuai dengan keadaan anda.",
      choices: [
        "Harga penawaran supplier tinggi",
        "Kualitas produk kurang memuaskan",
        "Sales tidak pro aktif",
        "Tidak bisa kirim sample",
        "Tidak bisa kirim ke banyak lokasi cabang",
        "Tidak ada sertifikat halal",
        "Kendala sistem pembayaran",
      ],
      isRequired: true,
      showOtherItem: true,
      colCount: 1,
      maxSelectedChoices: 2,
    },
    {
      type: "checkbox",
      name: "platform_pencarian",
      title: "Platform Yang Anda Gunakan Untuk Mencari Supplier",
      choices: [
        "Google",
        "Instagram",
        "Facebook",
        "TikTok",
        "Rekomendasi",
        "Ditawari oleh marketing supplier",
      ],
      isRequired: true,
      showOtherItem: true,
      colCount: 1,
      maxSelectedChoices: 2,
    },
    {
      type: "checkbox",
      name: "kerjasama_antar_supplier",
      title:
        "Bagaimana Cara Agar Kerjasama Dengan Supplier Dapat Bertahan Lama ?",
      description: "Pilih 2 yang menurut anda paling sesuai.",
      choices: [
        "Sistem pembayaran yang mudah",
        "Dapat kredit",
        "Bisa kirim ke banyak cabang",
        "Pengiriman tepat waktu",
        "Selalu ditawari oleh marketing supplier",
      ],
      isRequired: true,
      colCount: 1,
      maxSelectedChoices: 2,
    },
    {
      type: "checkbox",
      name: "berapa_supplier_ayam",
      title: "Berapa Supplier Ayam Yang Anda miliki ?",
      choices: ["1", "2", "3", "Lebih dari 3"],
      isRequired: true,
      colCount: 1,
      maxSelectedChoices: 1,
    },

    {
      type: "checkbox",
      name: "penurunan_kualitas",
      title:
        "Ketika Harga Sedang Tinggi Apakah Anda Bersedia Menurunkan Grade Quality Bahan Baku Agar Mendapatkan Harga Yang Lebih Terjangkau ?",
      choices: ["Bersedia", "Tidak"],
      isRequired: true,
      maxSelectedChoices: 1,
    },

    {
      type: "ranking",
      name: "rank_indikator_kepuasan",
      title: "Urutkan Indikator Kepuasan Pelanggan, Menurut Anda",
      description:
        '"Drag and Drop" / "Ketuk dan Tahan" untuk mengurutkan ranking dari prioritas yang paling tinggi (atas) ke yang paling rendah (bawah).',
      choices: [
        "Kualitas Produk",
        "Harga",
        "Ketepatan Kuantitas",
        "Ketepatan Pengiriman",
        "Kecepatan Pelayanan",
        "Kemudahan Transaksi",
      ],
      isRequired: true,
    },

    {
      type: "checkbox",
      name: "sumber_informasi",
      title: "Dari Mana Anda Tahu RPA Sukahati ?",
      description: "Pilih 2 yang paling mewakili.",
      choices: [
        "Google",
        "Instagram",
        "Facebook",
        "Marketing RPA Sukahati",
        "Rekomendasi",
        "Influencer",
      ],
      isRequired: true,
      showOtherItem: true,
      colCount: 1,
      maxSelectedChoices: 2,
    },

    {
      type: "checkbox",
      name: "kelebihan_sukahati",
      title: "Kelebihan Sukahati Dibandingkan Supplier Lain",
      description: "Pilih 2 yang paling mewakili.",
      choices: [
        "Kualitas",
        "Harga",
        "Ketepatan kuantitas",
        "Ketepatan pengiriman",
        "Kecepatan pelayanan",
        "Kemudahan sistem pembayaran",
        "Kemudahan menyelesaikan kendala",
      ],
      isRequired: true,
      showOtherItem: true,
      colCount: 1,
      maxSelectedChoices: 2,
    },

    {
      type: "checkbox",
      name: "posisi_sukahati",
      title: "Seberapa utama Sukahati",
      choices: ["Supplier utama", "Supplier kedua", "Supplier cadangan"],
      isRequired: true,
      colCount: 1,
      maxSelectedChoices: 1,
    },

    {
      type: "rating",
      name: "rating_kualitas_produk",
      title: "Rating Kualitas Produk",
      description: "Berikan penilaian kualitas produk RPA Sukahati",
      autoGenerate: false,
      rateCount: 6,
      rateValues: [1, 2, 3, 4, 5, 6],
      minRateDescription: "Sangat buruk",
      maxRateDescription: "Sangat baik",
      displayMode: "buttons",
    },

    {
      type: "rating",
      name: "rating_harga",
      title: "Rating Harga",
      description: "Berikan penilaian terkait harga produk RPA Sukahati",
      autoGenerate: false,
      rateCount: 6,
      rateValues: [1, 2, 3, 4, 5, 6],
      minRateDescription: "Sangat buruk",
      maxRateDescription: "Sangat baik",
      displayMode: "buttons",
    },

    {
      type: "rating",
      name: "rating_ketepatan_quantity",
      title: "Rating Ketepatan Kuantitas Produk",
      description:
        "Berikan penilaian terkait ketepatan kuantitas produk RPA Sukahati",
      autoGenerate: false,
      rateCount: 6,
      rateValues: [1, 2, 3, 4, 5, 6],
      minRateDescription: "Sangat buruk",
      maxRateDescription: "Sangat baik",
      displayMode: "buttons",
    },

    {
      type: "rating",
      name: "rating_ketepatan_pengiriman",
      title: "Rating Ketepatan Pengiriman",
      description:
        "Berikan penilaian terkait ketepatan pengiriman produk RPA Sukahati",
      autoGenerate: false,
      rateCount: 6,
      rateValues: [1, 2, 3, 4, 5, 6],
      minRateDescription: "Sangat buruk",
      maxRateDescription: "Sangat baik",
      displayMode: "buttons",
    },

    {
      type: "rating",
      name: "rating_kecepatan_layanan",
      title: "Rating Kecepatan Layanan",
      description: "Berikan penilaian terkait pelayanan RPA Sukahati",
      autoGenerate: false,
      rateCount: 6,
      rateValues: [1, 2, 3, 4, 5, 6],
      minRateDescription: "Sangat buruk",
      maxRateDescription: "Sangat baik",
      displayMode: "buttons",
    },

    {
      type: "rating",
      name: "rating_kemudahan_pembayaran",
      title: "Rating Kemudahan Pembayaran",
      description:
        "Berikan penilaian terkait kemudahan sistem pembayaran RPA Sukahati",
      autoGenerate: false,
      rateCount: 6,
      rateValues: [1, 2, 3, 4, 5, 6],
      minRateDescription: "Sangat buruk",
      maxRateDescription: "Sangat baik",
      displayMode: "buttons",
    },

    {
      type: "rating",
      name: "rating_tanggung_jawab",
      title: "Tanggung Jawab Menyelesaikan Kendala/Masalah Apabila terjadi",
      description:
        "Berikan penilaian RPA Sukahati dalam menyelesaikan masalah/kendala",
      autoGenerate: false,
      rateCount: 6,
      rateValues: [1, 2, 3, 4, 5, 6],
      minRateDescription: "Sangat buruk",
      maxRateDescription: "Sangat baik",
      displayMode: "buttons",
    },

    {
      type: "rating",
      name: "rating_rekomendasi",
      title:
        "Seberapa Besar Kemungkinan Anda Untuk Merekomendasikan RPA Sukahati Kepada Rekan Anda ?",
      autoGenerate: false,
      rateCount: 6,
      rateValues: [1, 2, 3, 4, 5, 6],
      minRateDescription: "Sangat buruk",
      maxRateDescription: "Sangat baik",
      displayMode: "buttons",
    },

    {
      name: "supplier_sebelumnya",
      title: "Nama Supplier Selain RPA Sukahati",
      type: "text",
    },

    {
      name: "kontak_supplier_sebelumnya",
      title: "Kontak Supplier Sebelumnya",
      type: "text",
      inputType: "tel",
      placeholder: "+6281000000000",
      validators: [
        {
          type: "regex",
          regex: "\\+[0-9]{2}[0-9]{4}[0-9]{3}[0-9]{4}",
          text: "Nomor telepon harus berformat: +62 8100-000-0000 ",
        },
      ],
    },

    {
      name: "kritik_saran",
      title: " Kritik dan Saran",
      type: "text",
    },

    {
      name: "pengalaman_customer",
      title: "Pengalaman/Kendala dengan RPA Sukahati yang membuat anda kecewa",
      type: "text",
    },
  ],
};

export default listQuestions;
